import { graphql } from 'gatsby';
import React from 'react'
import UseCases from '../templates/use-cases';
import { imageDataType } from './prevent-escalations';

function ImprovedResolutionTime({data}: {data : imageDataType}) {
  const pageData = {
    seoData: {
      title: "Improve your Resolution time for Proactive Customer Service | Aptedge",
      canonicalLink: "https://www.aptedge.io/improved-resolution-time/",
      metaDescription: {
          metaDescription: "Improve your Resolution time to Improve first contact resolutions and average handle time by 40% for Proactive Customer Service with Aptedge Knowledge Orchestration. ",
      },
      openGraphImage: {
          file: {
              url: "",
          },
      },
  },
    title: "Improved Resolution Time",
    heroImage: data.heroImg.childImageSharp.gatsbyImageData,

    reasons: {
      title:"Why do you need to improve resolution time?",
      content: "The number one request customers want customer support agents to help them resolve issues in one interaction.",
      image: data.reasonImg.childImageSharp.gatsbyImageData,
    },
    testimonial: [
      {
          company: "cisco",
          name: "Charles Coaxum",
          bio: "Former VP, Customer Experience",
          logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
          content: "AptEdge provides fast, accurate answers to all customer problems. We’re bringing AptEdge to our entire company."
      },
      {
        company: "hexagon",
        name: "Ryan Smith",
        bio: "Sr. Support Manager",
        logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
        content: "Using AptEdge to enable information discovery greatly reduces reliance on tacit knowledge in our organization."
      },
    ],
    dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    relatedCases: [
      {
        text: "Prevent Escalation",
        link: "/prevent-escalations/",
    },
    {
      text: "Improved CSAT",
      link: "/improved-csat/",
      },
      {
        text: "Ticket Volume Reduction",
        link: "/ticket-volume-reduction/",
      }
    ]
  }
  return (
    <UseCases pageData={pageData} />
  )
}

export default ImprovedResolutionTime;

export const query = graphql`
    query ImprovedResolutionReasonImage {
        reasonImg: file(relativePath: { eq: "use-cases/improve-resolution-reason.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/cisco-logo.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-hexagon.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "use-cases/hero-dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
      }`